

<template>
  <b-card body-text-variant="success" class="d-flex justify-content-center mb-1" style="max-width: 100rem; margin: auto; height: 100rem;">
    <iframe src="https://doc-ios.shadowtech.vip" frameborder="0" style="overflow:hidden;height:100%;width:100%" height="300rem" width="100%"></iframe>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination,BSpinner, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BMedia, BLink, BCard, BFormTextarea, BForm
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, } from 'vee-validate'
import RepositoryFactory from '../../api/RepositoryFactory'
const Supports = RepositoryFactory.get('suports')

import {  required } from '@validations'

const ticketObj = {
  subject: "",
  message: "",
}
export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BLink,
    BCard,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BSpinner
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [5, 10, 20,50,100],
      totalRows: 100,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      loading: false,
      email: "",
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      items:[],
      fields: [
        { key: 'from', label: 'From', sortable: true },
        { key: 'subject', label: 'Subject', sortable: true },
        { key: 'created_at', label: 'Date', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        {
          key: '_id', label: 'ACtion',
        },
      ],
      status: [{
        1: 'Success', 2: 'Pending', 3: 'Rejected', 4: 'Refund',
      },
      {
        1: 'light-success', 2: 'light-warning', 3: 'light-danger', "New": 'light-info',
      }],
      ticketForm: Object.assign({},ticketObj),
      required
    }
  },
  created(){
    this.loading = true
    Supports.fetch().then(rs=>{
      this.loading = false
      this.email = this.$store.getters['auth/userInfo'].email
      this.items = rs.data.data
      
    })
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    mountData(){
      this.totalRows = this.items.length
    },
    showModal(){
      this.$bvModal.show('modal-add-ticket')
    },
    direct(id){
      this.$router.push('/support/helpdesk/'+id)
    },
    onSubmit(event){
      event.preventDefault()
      Supports.createTicket(this.ticketForm).then(rs=>{

        let data = rs.data.data
        delete data['message']
        this.items.push(data)
        this.$bvModal.hide('modal-add-ticket')
      }).catch(e=>{

      })
    },
    getTimeInterval(date) {
      let seconds = Math.floor((Date.now() - date) / 1000);
      let unit = "second";
      let direction = "ago";
      if (seconds < 0) {
        seconds = -seconds;
        direction = "from now";
      }
      let value = seconds;
      if (seconds >= 31536000) {
        value = Math.floor(seconds / 31536000);
        unit = "year";
      } else if (seconds >= 86400) {
        value = Math.floor(seconds / 86400);
        unit = "day";
      } else if (seconds >= 3600) {
        value = Math.floor(seconds / 3600);
        unit = "hour";
      } else if (seconds >= 60) {
        value = Math.floor(seconds / 60);
        unit = "minute";
      }
      if (value != 1)
        unit = unit + "s";
      return value + " " + unit + " " + direction;
    }
  },
}
</script>
